import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {Col, Row} from "react-bootstrap"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "startseite.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div>

  <Img fluid={data.hero.childImageSharp.fluid} style={{height: `300px`}}/>
  <Row className="text-center" >
               <Col sm style={{
            marginTop: `-12rem`,
             }}>
            <h1>
              <span style={{
            color: `#fff`,
            borderTop: `thick double`,
            borderBottom: `thick double`,
            whiteSpace: `nowrap`,
              fontSize: '5rem',
            letterSpacing: `20px`,
             }}>
              DXE</span></h1>
          <div style={{
            marginTop:`1rem`,
            color: `#eee`,
            letterSpacing: `5px`,
          }}>INKFS - Digitale Erlebniswelten
          </div>
            </Col>
    </Row>
    <div className="clearfix"></div>

    
    </div>
  )
}

export default Image

